var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "task-con" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 24 } },
        [
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, md: 24, lg: 18, xl: 18 } },
            [
              _c(
                "div",
                { staticClass: "panel clearfix" },
                [
                  _c("p", {
                    staticClass: "task-tit",
                    domProps: { textContent: _vm._s(_vm.testTask.title) },
                  }),
                  _c(
                    "el-button",
                    {
                      staticClass: "fr",
                      attrs: { size: "mini", type: "primary" },
                    },
                    [_vm._v("答题中")]
                  ),
                ],
                1
              ),
              _vm._l(_vm.testTask.questionList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "panel",
                    attrs: { id: "anchor_" + (index + 1) },
                  },
                  [
                    _c(
                      "el-row",
                      { staticClass: "topicTit" },
                      [
                        _c("span", { staticClass: "top-num" }, [
                          _vm._v(_vm._s(index + 1) + "."),
                        ]),
                        _c("span", { staticClass: "top-type" }, [
                          _vm._v("【 " + _vm._s(item.question.type) + " 】"),
                        ]),
                        _vm.testTask.isShowScore === "是"
                          ? [
                              _c("span", { staticClass: "top-score" }, [
                                _vm._v(_vm._s(item.score) + " 分"),
                              ]),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                    _c(
                      "el-row",
                      [
                        _c("el-row", {
                          staticClass: "task-title",
                          domProps: { innerHTML: _vm._s(item.question.stem) },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "ul",
                      { staticClass: "label-con" },
                      _vm._l(item.question.itemList, function (label, index) {
                        return _c(
                          "li",
                          { key: index, staticClass: "label-list clearfix" },
                          [
                            _c("span", {
                              staticClass: "fl mr10",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.optionLetterName(index) + "."
                                ),
                              },
                            }),
                            _c("div", {
                              staticClass: "fl",
                              domProps: { innerHTML: _vm._s(label.content) },
                            }),
                          ]
                        )
                      }),
                      0
                    ),
                    item.question.type === "单选"
                      ? _vm._l(item.question.itemList, function (label, index) {
                          return _c(
                            "el-radio-group",
                            {
                              key: index,
                              on: { change: _vm.forceUpdate },
                              model: {
                                value: item.answer,
                                callback: function ($$v) {
                                  _vm.$set(item, "answer", $$v)
                                },
                                expression: "item.answer",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: label.id } }, [
                                _vm._v(_vm._s(_vm.optionLetterName(index))),
                              ]),
                            ],
                            1
                          )
                        })
                      : _vm._e(),
                    item.question.type === "多选"
                      ? _vm._l(item.question.itemList, function (label, index) {
                          return _c(
                            "el-checkbox-group",
                            {
                              key: index,
                              on: { change: _vm.forceUpdate },
                              model: {
                                value: item.answer,
                                callback: function ($$v) {
                                  _vm.$set(item, "answer", $$v)
                                },
                                expression: "item.answer",
                              },
                            },
                            [
                              _c(
                                "el-checkbox",
                                { attrs: { label: label.id } },
                                [_vm._v(_vm._s(_vm.optionLetterName(index)))]
                              ),
                            ],
                            1
                          )
                        })
                      : _vm._e(),
                    item.question.type === "简答"
                      ? [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              rows: 5,
                              placeholder: "请输入你的答案",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.forceUpdate($event)
                              },
                            },
                            model: {
                              value: item.answer,
                              callback: function ($$v) {
                                _vm.$set(item, "answer", $$v)
                              },
                              expression: "item.answer",
                            },
                          }),
                        ]
                      : _vm._e(),
                    item.question.type === "填空"
                      ? _vm._l(item.answer, function (ele, index) {
                          return _c("el-input", {
                            key: index,
                            staticStyle: { "margin-bottom": "10px" },
                            attrs: { placeholder: "请按填空顺序输入填空答案" },
                            on: {
                              input: function ($event) {
                                return _vm.forceUpdate($event)
                              },
                            },
                            model: {
                              value: ele.value,
                              callback: function ($$v) {
                                _vm.$set(ele, "value", $$v)
                              },
                              expression: "ele.value",
                            },
                          })
                        })
                      : _vm._e(),
                  ],
                  2
                )
              }),
            ],
            2
          ),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, md: 24, lg: 6, xl: 6 } },
            [
              _c("el-row", { staticClass: "task-fixed" }, [
                _vm.testTask.limitTime !== 0
                  ? _c("div", { staticClass: "task-time" }, [
                      _c("p", [
                        _vm._v(" 倒计时： "),
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.hr) +
                              ":" +
                              _vm._s(_vm.min) +
                              ":" +
                              _vm._s(_vm.sec) +
                              " "
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "task-dtk" },
                  [
                    _c("h5", { staticClass: "card-tit" }, [_vm._v("答题卡")]),
                    _c(
                      "ul",
                      {
                        class: [
                          Boolean(_vm.isSeen)
                            ? "card-list-show"
                            : "card-list" + " clearfix",
                        ],
                        on: {
                          mouseenter: _vm.onMouseOver,
                          mouseleave: _vm.onMouseOut,
                        },
                      },
                      _vm._l(_vm.testTask.questionList, function (item, index) {
                        return _c(
                          "li",
                          {
                            key: index,
                            class: _vm.isRespondence(item),
                            on: {
                              click: function ($event) {
                                return _vm.skipAnchor(index + 1)
                              },
                            },
                          },
                          [_vm._v(_vm._s(index + 1) + " ")]
                        )
                      }),
                      0
                    ),
                    _c(
                      "el-row",
                      { staticStyle: { "text-align": "center" } },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { color: "#212121", margin: "10px 0" },
                            attrs: {
                              size: "small",
                              round: "",
                              type: "primary",
                            },
                            on: { click: _vm.SubPaper },
                          },
                          [_vm._v("提交试卷 ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c("exam-status", {
            attrs: { show: _vm.statusDialog, statusText: _vm.statusText },
            on: {
              close: function ($event) {
                ;(_vm.statusDialog = false), (_vm.statusText = "")
              },
              checkResult: _vm.checkResult,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }