var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sourse-boxs" },
    [
      _c(
        "el-form",
        {
          ref: "queryForm",
          attrs: {
            model: _vm.queryParams,
            "label-width": "16px",
            size: "small",
          },
        },
        [
          _c(
            "el-form-item",
            { staticStyle: { "text-align": "right" } },
            [
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-refresh", size: "small" },
                  on: { click: _vm.handleQuery },
                },
                [_vm._v("刷新")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    type: "primary",
                    icon: "el-icon-upload",
                  },
                  on: { click: () => (_vm.uploadModal = true) },
                },
                [_vm._v("上传")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { size: "mini", data: _vm.sourseList },
        },
        [
          _c("el-table-column", {
            attrs: {
              "show-overflow-tooltip": "",
              label: "名称",
              align: "center",
              prop: "name",
              width: "340",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-link",
                      {
                        staticClass: "oneline-style",
                        staticStyle: { color: "#f5c319" },
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.resetNameHandler(scope.row)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(scope.row.name) + " ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              "show-overflow-tooltip": "",
              label: "类型",
              align: "center",
              prop: "type",
            },
          }),
          _c("el-table-column", {
            staticStyle: { "padding-top": "6px" },
            attrs: {
              "show-overflow-tooltip": "",
              label: "预览",
              align: "center",
              prop: "url",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticStyle: { "padding-top": "6px" } }, [
                      _c("img", {
                        staticClass: "hover-style",
                        staticStyle: { height: "34px" },
                        attrs: { src: scope.row.url },
                        on: {
                          click: function ($event) {
                            return _vm.scanHandler(scope.row)
                          },
                        },
                      }),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              align: "center",
              "class-name": "small-padding fixed-width",
              width: "200",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.resetNameHandler(scope.row)
                          },
                        },
                      },
                      [_vm._v(" 重命名 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("loadToEdit", scope.row)
                          },
                        },
                      },
                      [_vm._v(" 添加到作品中 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        staticStyle: { padding: "10px 20px", "text-align": "center" },
        attrs: {
          background: "",
          "page-size": _vm.queryParams.pageSize,
          total: _vm.queryParams.total,
          "current-page": _vm.queryParams.currentPage,
          "page-sizes": [10, 20, 30, 50],
          layout: "total, sizes, prev, pager, next, jumper",
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "文件上传",
            visible: _vm.uploadModal,
            width: "800px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.uploadModal = $event
            },
          },
        },
        [
          _vm.uploadModal
            ? _c("UploadSource", {
                on: {
                  getSoureListByUserId: _vm.getSoureListByUserId,
                  cancel: function ($event) {
                    _vm.uploadModal = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "素材预览",
            visible: _vm.scanModal,
            width: "800px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.scanModal = $event
            },
          },
        },
        [
          _vm.scanModal
            ? _c("PreviewSource", {
                attrs: { scanInfo: _vm.scanInfo },
                on: {
                  cancel: function ($event) {
                    _vm.scanModal = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "素材重命名",
            visible: _vm.resetNameModal,
            width: "800px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.resetNameModal = $event
            },
          },
        },
        [
          _vm.resetNameModal
            ? _c("RenameSource", {
                ref: "name",
                attrs: { formInfos: _vm.formInfo, flag: true },
                on: {
                  cancel: function ($event) {
                    _vm.resetNameModal = false
                  },
                  renameFile: _vm.saveResetName,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }