var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-container",
    [
      _c(
        "el-header",
        {
          staticStyle: { "background-color": "#212121", "line-height": "60px" },
        },
        [
          _c("p", { staticClass: "fl", staticStyle: { color: "#fff" } }, [
            _vm._v(_vm._s(_vm.taskInfo.title)),
          ]),
          _c("div", { staticClass: "fl header-left" }, [
            _c(
              "div",
              { staticClass: "parenttwo fl" },
              [
                _c("el-button", {
                  staticClass: "btnbg",
                  attrs: { icon: "el-icon-arrow-left", type: "text" },
                  on: { click: _vm.gotoPreQuestion },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "parentone fl" }, [
              _c("span", { staticClass: "titlequestion questionPanel" }, [
                _vm._v(_vm._s(_vm.titleText)),
              ]),
              _c(
                "div",
                [
                  _c(
                    "el-dropdown",
                    {
                      attrs: { trigger: "click" },
                      on: { command: _vm.handleCommand },
                    },
                    [
                      _c("span", { staticClass: "el-dropdown-link" }, [
                        _c("span", {
                          staticClass: "el-icon-arrow-down",
                          staticStyle: {
                            top: "0px",
                            left: "0px",
                            "font-size": "16px",
                            color: "#FFFFFF",
                          },
                        }),
                      ]),
                      _c(
                        "el-dropdown-menu",
                        {
                          staticClass: "questionlist",
                          attrs: { slot: "dropdown" },
                          slot: "dropdown",
                        },
                        _vm._l(_vm.questionList, function (item, index) {
                          return _c(
                            "el-dropdown-item",
                            {
                              key: index,
                              staticClass: "questionlistItemClass",
                              attrs: { command: item },
                            },
                            [
                              _vm._v(
                                _vm._s(index + 1) +
                                  ".  " +
                                  _vm._s(item.problemName) +
                                  " "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "parenttwo fl" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "btnbg",
                    attrs: { type: "text" },
                    on: { click: _vm.gotoAfterQuestion },
                  },
                  [
                    _c("i", {
                      staticClass: "el-icon-arrow-right el-icon--right",
                    }),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "parenttwo fl",
                staticStyle: { "margin-left": "20px" },
              },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "btnbg",
                    attrs: { type: "text" },
                    on: { click: _vm.gotorecord },
                  },
                  [
                    _c("i", { staticClass: "el-icon-document el-document" }),
                    _vm._v("我的提交 "),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c("el-main", { staticStyle: { overflow: "hidden" } }, [
        _c(
          "div",
          { staticClass: "components-container" },
          [
            _c(
              "split-pane",
              {
                attrs: {
                  split: "vertical",
                  "min-percent": 25,
                  "default-percent": 40,
                },
              },
              [
                _c("template", { slot: "paneL" }, [
                  _c(
                    "div",
                    { staticClass: "left-container grid-content bg-purple" },
                    [
                      _c(
                        "el-row",
                        [
                          _c("el-col", { staticClass: "parentitem" }, [
                            _c("div", { staticClass: "titlequestion" }, [
                              _vm._v(_vm._s(_vm.titleText)),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "decription",
                                staticStyle: { "margin-top": "20px" },
                              },
                              [
                                _vm._v(
                                  " 时间限制：" +
                                    _vm._s(_vm.timeLimit) +
                                    "秒        "
                                ),
                                _c("span", [
                                  _vm._v(
                                    "内存限制：" + _vm._s(_vm.memoryLimit) + "M"
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                          _vm.dataObejcet.editFlag === "1"
                            ? _c(
                                "div",
                                [
                                  _c("el-col", { staticClass: "parentitem" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "titlequestion",
                                        staticStyle: { display: "flex" },
                                      },
                                      [_vm._v("题目描述")]
                                    ),
                                    _c("div", {
                                      staticClass: "decription",
                                      staticStyle: { "margin-top": "20px" },
                                      attrs: { id: "description" },
                                    }),
                                  ]),
                                  _c(
                                    "el-col",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.inputshow,
                                          expression: "inputshow",
                                        },
                                      ],
                                      staticClass: "parentitem",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "titlequestion",
                                          staticStyle: { display: "flex" },
                                        },
                                        [_vm._v("输入描述")]
                                      ),
                                      _c("div", {
                                        staticClass: "decription",
                                        staticStyle: { "margin-top": "20px" },
                                        attrs: { id: "input" },
                                      }),
                                    ]
                                  ),
                                  _c("el-col", { staticClass: "parentitem" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "titlequestion",
                                        staticStyle: { display: "flex" },
                                      },
                                      [_vm._v("输出描述")]
                                    ),
                                    _c("div", {
                                      staticClass: "decription",
                                      staticStyle: { "margin-top": "20px" },
                                      attrs: { id: "output" },
                                    }),
                                  ]),
                                  _c("el-col", { staticClass: "parentitem" }, [
                                    _c(
                                      "div",
                                      { staticClass: "titlequestion" },
                                      [_vm._v("样例")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.sampleinputshow,
                                            expression: "sampleinputshow",
                                          },
                                        ],
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "decription",
                                            staticStyle: {
                                              "margin-top": "20px",
                                            },
                                          },
                                          [_vm._v("输入")]
                                        ),
                                        _c("div", {
                                          staticClass: "entercontent",
                                          attrs: { id: "sample_input" },
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "decription",
                                        staticStyle: { "margin-top": "20px" },
                                      },
                                      [_vm._v("输出")]
                                    ),
                                    _c("div", {
                                      staticClass: "entercontent",
                                      attrs: { id: "sample_output" },
                                    }),
                                  ]),
                                  _c(
                                    "el-col",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.tipshow,
                                          expression: "tipshow",
                                        },
                                      ],
                                      staticClass: "parentitem",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "titlequestion",
                                          staticStyle: { display: "flex" },
                                        },
                                        [_vm._v("提示")]
                                      ),
                                      _c("div", {
                                        staticClass: "decription",
                                        staticStyle: {
                                          "margin-top": "20px",
                                          "margin-bottom": "80px",
                                        },
                                        attrs: { id: "hint" },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.dataObejcet.editFlag === "2"
                            ? _c(
                                "el-col",
                                [
                                  _c("el-card", { staticClass: "parentitem" }, [
                                    _c(
                                      "div",
                                      {
                                        key: _vm.problem_id,
                                        attrs: { id: "test-editormd-view" },
                                      },
                                      [
                                        _c("textarea", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.currentDescript,
                                              expression: "currentDescript",
                                            },
                                          ],
                                          staticStyle: { display: "none" },
                                          domProps: {
                                            value: _vm.currentDescript,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.currentDescript =
                                                $event.target.value
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "template",
                  { slot: "paneR" },
                  [
                    _c(
                      "split-pane",
                      {
                        attrs: {
                          split: "horizontal",
                          "min-percent": _vm.showace ? 22 : 0,
                          "default-percent": _vm.showace ? 78 : 100,
                        },
                        on: { resize: _vm.resize },
                      },
                      [
                        _c("template", { slot: "paneL" }, [
                          _c("div", { staticClass: "top-container" }, [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.showace,
                                    expression: "!showace",
                                  },
                                ],
                                staticClass: "rightitem",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    on: { click: _vm.gotoresultdetail },
                                  },
                                  [
                                    _c(
                                      "el-row",
                                      { staticClass: "rightcontent" },
                                      [
                                        _c(
                                          "el-col",
                                          { staticClass: "titlequestion" },
                                          [_vm._v("我的提交")]
                                        ),
                                        _c(
                                          "el-col",
                                          { staticClass: "secondtitle colcls" },
                                          [
                                            _c("span", [_vm._v("提交时间： ")]),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("dateFormat")(
                                                    _vm.submittime
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "el-col",
                                          { staticClass: "secondtitle colcls" },
                                          [
                                            _c("span", [_vm._v("提交结果： ")]),
                                            _c("span", {
                                              style:
                                                "color" +
                                                ":" +
                                                _vm.getStateColor(
                                                  _vm.resultNumber
                                                ),
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.getSubType(
                                                    _vm.resultNumber
                                                  )
                                                ),
                                              },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            staticClass:
                                              "decription showmessage",
                                          },
                                          [
                                            _c("div", [
                                              _c("i", {
                                                staticClass:
                                                  "iconfont icon-yongshi ico-time",
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "5px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.onedata) + "Ms"
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "margin-left": "15px",
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "iconfont icon-qian ico-bulk",
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "5px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.twodata) + "M"
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "margin-left": "15px",
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "iconfont icon-storage-capacity-copy",
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "5px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.threedata) +
                                                        "KB"
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: {
                                          "margin-top": "60px",
                                          "margin-left": "30px",
                                        },
                                        attrs: { type: "warning" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.editorCode()
                                          },
                                        },
                                      },
                                      [_vm._v("编辑代码 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.showace,
                                    expression: "showace",
                                  },
                                ],
                                staticStyle: {
                                  display: "flex",
                                  height: "100%",
                                  "flex-direction": "column",
                                  background: "#181E22",
                                },
                                attrs: { id: "boxtwo" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "edittop" },
                                  [
                                    _c(
                                      "el-dropdown",
                                      { on: { command: _vm.setmodemessage } },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              background: "#516169",
                                              border: "none",
                                              "margin-left": "10px",
                                            },
                                            attrs: { type: "primary" },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.currentCode) +
                                                " "
                                            ),
                                            _c("i", {
                                              staticClass:
                                                "el-icon-arrow-down el-icon--right",
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "el-dropdown-menu",
                                          {
                                            staticStyle: {
                                              width: "8%",
                                              "text-align": "center",
                                            },
                                            attrs: { slot: "dropdown" },
                                            slot: "dropdown",
                                          },
                                          _vm._l(
                                            _vm.topicModeArray,
                                            function (item, index) {
                                              return _c(
                                                "el-dropdown-item",
                                                {
                                                  key: index,
                                                  attrs: { command: item },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.name) + " "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              background: "#516169",
                                              border: "none",
                                              "margin-right": "15px",
                                            },
                                            attrs: { type: "primary" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.closeCode()
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-share",
                                              staticStyle: {
                                                "margin-right": "10px",
                                              },
                                            }),
                                            _vm._v("收起代码编辑区 "),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c("div", {
                                  ref: "ace",
                                  staticClass: "ace-editor",
                                  attrs: { id: "toptwo" },
                                }),
                              ]
                            ),
                          ]),
                        ]),
                        _vm.showace
                          ? _c("template", { slot: "paneR" }, [
                              _c("div", { staticClass: "bottom-container" }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "background-color": "rgb(24, 30, 34)",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "prevent-re-click",
                                            rawName: "v-prevent-re-click",
                                            value: 5000,
                                            expression: "5000",
                                          },
                                        ],
                                        staticStyle: { margin: "10px" },
                                        attrs: { type: "warning" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.submitCode()
                                          },
                                        },
                                      },
                                      [_vm._v("提交代码 ")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.showresult,
                                        expression: "showresult",
                                      },
                                    ],
                                    staticStyle: {
                                      "background-color": "#3d444c",
                                      height: "100%",
                                    },
                                    attrs: { id: "bottomtwo" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.getresult,
                                            expression: "!getresult",
                                          },
                                        ],
                                        attrs: { id: "tipsone" },
                                      },
                                      [
                                        _c("div", { staticClass: "tipclass" }, [
                                          _vm._v("您的代码已经保存"),
                                        ]),
                                        _c("div", { staticClass: "tipclass" }, [
                                          _vm._v("正在查询结果..."),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.getresult,
                                            expression: "getresult",
                                          },
                                        ],
                                        attrs: { id: "tipstwo" },
                                      },
                                      [
                                        _c("div", { staticClass: "tipclass" }, [
                                          _c("span", {
                                            style:
                                              "color" +
                                              ":" +
                                              _vm.getStateColor(
                                                _vm.currentResultNumber
                                              ),
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.getSubType(
                                                  _vm.currentResultNumber
                                                )
                                              ),
                                            },
                                          }),
                                        ]),
                                        _c(
                                          "div",
                                          { staticStyle: { height: "100%" } },
                                          [
                                            _c("div", {
                                              staticClass: "tipclass",
                                              attrs: { id: "bianyiText" },
                                            }),
                                            _c("div", {
                                              staticClass: "tipclass",
                                              attrs: { id: "runtimeText" },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }