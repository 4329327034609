<template>
  <div>
    <el-upload
        class="upload-sourse"
        ref="upload"
        :limit="1"
        :action="uploadUrl"
        :headers="headers"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :file-list="fileList"
        :before-upload="beforeAvatarUpload"
        :on-success="handleAvatarSuccess"
        :on-error="handleAvatarFail"
        :auto-upload="false"
        :accept="accept"
        :on-exceed="handleExceed"
      >
        <el-button slot="trigger" size="small" type="primary"
          >选取文件</el-button
        >
        <el-button
          style="margin-left: 10px"
          size="small"
          type="success"
          @click="submitUpload"
          >上传到服务器</el-button
        >
        <div slot="tip" class="el-upload__tip">
          上传图片文件:  png, jpg, jpeg, gif, bmp
          <!-- ，且不超过500kb -->
        </div>
      </el-upload>
  </div>
</template>
<script>
import {
  addSource,
} from "@/api/works/sourceedu";
export default {
  name: "SourceUpload",
  data() {
    return {
      uploadUrl: process.env.VUE_APP_API_ADDRESS + "/common/file/uploadMap",
      headers: {
        Authorization: localStorage.getItem("token"),
        authorization: localStorage.getItem("token"),
      },
      accept:
        // ".mp3, .wma, .rm, .wav, .mid, .ape, .flac, .png, .jpg, .gif, .svg",
        ".png,.jpg,.jpeg,.gif,.bmp",
      sourseList: [],
      fileList: [],
      currentFile: {},
    };
  },
  methods: {
    submitUpload() {
      this.$refs.upload.submit();
    },
    handleRemove(file, fileList) {
      // console.log(file, fileList);
    },
    handlePreview(file) {
      // console.log(file);
    },
    handleAvatarFail(err, res, file) {
      $msg("上传文件失败", 2);
    },
    handleAvatarSuccess(res, file) {
      if (res && res.state == "success") {
        addSource({
          type: "图片",
          url: res.body.fileUrl,
          name: this.currentFile.name,
        }).then((res) => {
          $msg("上传文件成功", 0);
          this.$emit('getSoureListByUserId');
          this.$emit('cancel')
        });
      } else {
        $msg("上传文件失败", 2);
      }
    },
    beforeAvatarUpload(file) {
      this.currentFile = file;
       if (
        file.type !== "image/png" &&
        file.type !== "image/jpeg" &&
        file.type !== "image/jpg" &&
        file.type !== "image/gif" &&
        file.type !== "image/bmp" &&
        file.type !== ""
      ) {
        this.$message.warning("请上指定文件类型！");
        return;
      }
      const isLt2M = file.size / 1024 / 1024 < 200;
      if (!isLt2M) {
        this.$message.error("上传文件不能超过 200MB!");
      }
      return isLt2M;
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件`);
    },
  },
};
</script>