/* 
* @Author: lidianzhong  
* @Date: 2022-01-04 10:25:56  
* @Last Modified by: lidianzhong
* @Last Modified time: 2022-01-04 10:25:56  
* @descript: ScratchJr作品列表页面
*/
<template>
  <el-dialog
    top="20px"
    title="列表信息"
    :visible.sync="worksInfo"
    v-if="worksInfo"
    width="800px"
    @close="close"
    @open="getWorksList"
    class="dialog-style"
  >
    <el-row :gutter="40" class="item-box">
      <el-col
        v-for="(item, index) in PythonList"
        :key="index"
        :span="8"
        style="margin-top: 10px"
      >
        <div
          class="item-style"
          @click="showItem(item)"
          style="text-align: center"
        >
          <div style="padding: 10px">
            <img
              height="140"
              width="180"
              :src="item.cover ? item.cover : '/scratchJr/svglibrary/Jr.png'"
              alt=""
            />
          </div>
          <h4>{{ item.title }}</h4>
        </div>
      </el-col>
    </el-row>
    <!-- <el-pagination
      background
      :page-size="pageInfo.pageSize"
      :total="pageInfo.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageInfo.currentPage"
      :page-sizes="[9, 18, 36, 72]"
      layout="total, sizes, prev, pager, next, jumper"
      style="padding: 10px 20px; text-align: center"
    /> -->
  </el-dialog>
</template>
<script>
import { getWorksList } from "@/api/works/index";
import { taskScratchList, getListByUser } from "@/apiteacher/teacher";
///edu/task/uploadScratch

export default {
  props: {
    taskId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      worksInfo: false,
      pageInfo: {
        currentPage: 1,
        total: 0,
        pageSize: 9, //
        pageCount: 0, //总页数
        needCount: true,
      },
      pageSize: 9,
      PythonList: [],
    };
  },
  created() {
    this.getWorksList();
  },

  methods: {
    //分页
    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.getWorksList();
    },
    handleCurrentChange(val) {
      this.pageInfo.currentPage = val;
      this.getWorksList();
    },
    getWorksList() {
      console.log('获取列表')
      if (!this.taskId) {
        return;
      }
      let params = {
        taskId: this.taskId,
        pageNum: 1,
        pageSize: 10000,
        category: "ScratchJr",
      };
      getListByUser(params).then((res) => {
        this.PythonList = res.body.list;
        this.pageInfo.total = res.body.total;
      });
    },
    //
    showItem(item) {
      this.$emit("showItem", item);
    },
    open() {
      this.worksInfo = true;
    },
    close() {
      this.worksInfo = false;
    },
  },
};
</script>
<style scoped lang="scss">
.item-box {
  margin-left: 0 !important;
  margin-right: 0 !important;
  height: 460px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid #eee;
}
.item-style {
  height: 200px;
  width: 100%;
  border: 1px solid #eee;
  padding: 10px;
  box-shadow: 4px 4px 2px #eee;
  border-radius: 10px;
  cursor: pointer;
}
.item-style:hover {
  transform: translate(0, -4px);
  transition: all 0.6s;
}
</style>
<style lang="scss">
.dialog-style {
  .el-dialog__body {
    padding: 0;
  }
}
</style>