var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          ref: "upload",
          staticClass: "upload-sourse",
          attrs: {
            limit: 1,
            action: _vm.uploadUrl,
            headers: _vm.headers,
            "on-preview": _vm.handlePreview,
            "on-remove": _vm.handleRemove,
            "file-list": _vm.fileList,
            "before-upload": _vm.beforeAvatarUpload,
            "on-success": _vm.handleAvatarSuccess,
            "on-error": _vm.handleAvatarFail,
            "auto-upload": false,
            accept: _vm.accept,
            "on-exceed": _vm.handleExceed,
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: { slot: "trigger", size: "small", type: "primary" },
              slot: "trigger",
            },
            [_vm._v("选取文件")]
          ),
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "10px" },
              attrs: { size: "small", type: "success" },
              on: { click: _vm.submitUpload },
            },
            [_vm._v("上传到服务器")]
          ),
          _c(
            "div",
            {
              staticClass: "el-upload__tip",
              attrs: { slot: "tip" },
              slot: "tip",
            },
            [_vm._v(" 上传图片文件: png, jpg, jpeg, gif, bmp ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }