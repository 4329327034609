var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-container",
    { staticClass: "container" },
    [
      _c(
        "el-main",
        { class: _vm.getStateTaskInfo(_vm.taskInfo.type) },
        [
          _vm.taskInfo.type === "图文"
            ? [
                _c(
                  "el-row",
                  { staticClass: "content" },
                  [
                    _c("el-col", { attrs: { span: 20 } }, [
                      _c("div", {
                        staticStyle: { "line-height": "25px" },
                        domProps: {
                          innerHTML: _vm._s(_vm.taskInfo.sourceContent),
                        },
                      }),
                    ]),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.taskInfo.type === "上传"
            ? [
                _c(
                  "el-row",
                  { staticClass: "content" },
                  [
                    _c("el-col", { attrs: { span: 20 } }, [
                      _c("div", {
                        staticStyle: { "line-height": "25px" },
                        domProps: {
                          innerHTML: _vm._s(_vm.taskInfo.sourceContent),
                        },
                      }),
                    ]),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.taskInfo.type === "外链"
            ? [
                _c("iframe", {
                  attrs: {
                    src: _vm.taskInfo.sourceContent,
                    frameborder: "1",
                    allowfullscreen: "true",
                    width: "100%",
                    height: "99%",
                    seamless: "",
                    sandbox:
                      "allow-downloads allow-scripts allow-modals allow-forms allow-top-navigation allow-same-origin allow-popups",
                  },
                }),
              ]
            : _vm._e(),
          _vm.taskInfo.type === "python"
            ? [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      "line-height": "60px",
                      height: "60px",
                    },
                  },
                  [
                    _vm.editPython !== null
                      ? [
                          _c(
                            "span",
                            { staticStyle: { "margin-right": "15px" } },
                            [
                              _vm._v(
                                " 作品名称：" +
                                  _vm._s(_vm.editPython.title) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "el-button",
                            {
                              staticStyle: { "margin-right": "15px" },
                              attrs: {
                                size: "small",
                                type: "primary",
                                round: "",
                                loading: _vm.isGoShow,
                              },
                              on: { click: _vm.savePython },
                            },
                            [_vm._v("保 存 ")]
                          ),
                          _c(
                            "el-popover",
                            {
                              ref: "taskPover",
                              staticClass: "mr-10",
                              attrs: {
                                placement: "top",
                                width: "350",
                                trigger: "click",
                              },
                            },
                            [
                              _c(
                                "el-form",
                                {
                                  ref: "taskPoverForm",
                                  attrs: {
                                    model: _vm.formData,
                                    rules: _vm.rules,
                                    "label-width": "100px",
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "作品名称：",
                                        prop: "titleBesides",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "请输入作品名称",
                                        },
                                        on: { input: _vm.forceUpdate },
                                        model: {
                                          value: _vm.formData.titleBesides,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "titleBesides",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "formData.titleBesides",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "text-align": "right",
                                    margin: "0",
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.taskConfirm(
                                            "python",
                                            "again"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("确 定")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "info", size: "mini" },
                                      on: { click: _vm.taskClose },
                                    },
                                    [_vm._v("取 消")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-button",
                                {
                                  staticStyle: { "margin-right": "15px" },
                                  attrs: {
                                    slot: "reference",
                                    size: "small",
                                    type: "primary",
                                    round: "",
                                    loading: _vm.isGoShow,
                                  },
                                  slot: "reference",
                                },
                                [_vm._v("另存为 ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      : [
                          _c(
                            "el-popover",
                            {
                              ref: "taskPover",
                              staticClass: "mr-10",
                              attrs: {
                                placement: "top",
                                width: "350",
                                trigger: "click",
                              },
                            },
                            [
                              _c(
                                "el-form",
                                {
                                  ref: "taskPoverForm",
                                  attrs: {
                                    model: _vm.formData,
                                    rules: _vm.rules,
                                    "label-width": "100px",
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "作品名称：",
                                        prop: "title",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "请输入作品名称",
                                        },
                                        on: { input: _vm.forceUpdate },
                                        model: {
                                          value: _vm.formData.title,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "title",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "formData.title",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "text-align": "right",
                                    margin: "0",
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.taskConfirm("python")
                                        },
                                      },
                                    },
                                    [_vm._v("确 定")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "info", size: "mini" },
                                      on: { click: _vm.taskClose },
                                    },
                                    [_vm._v("取 消")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-button",
                                {
                                  staticStyle: { "margin-right": "15px" },
                                  attrs: {
                                    slot: "reference",
                                    size: "small",
                                    type: "primary",
                                    round: "",
                                    loading: _vm.isGoShow,
                                  },
                                  slot: "reference",
                                },
                                [_vm._v("保 存 ")]
                              ),
                            ],
                            1
                          ),
                        ],
                    _c(
                      "el-popover",
                      {
                        ref: "popoverPython",
                        staticStyle: { "margin-right": "25px" },
                        attrs: {
                          placement: "top-end",
                          width: "230",
                          trigger: "click",
                        },
                        on: { show: _vm.getListPythonTaskCode },
                      },
                      [
                        _vm.pythonList.length === 0
                          ? [
                              _c(
                                "el-row",
                                { staticStyle: { "text-align": "center" } },
                                [_c("span", [_vm._v("暂无提交记录")])]
                              ),
                            ]
                          : _c(
                              "ul",
                              { staticClass: "python-list record-list" },
                              _vm._l(_vm.pythonList, function (item) {
                                return _c("li", { key: item.id }, [
                                  _c(
                                    "p",
                                    { staticClass: "clearfix" },
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "fl",
                                          attrs: {
                                            effect: "dark",
                                            content: item.createTime,
                                            placement: "top-start",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "title-link pointer",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.previewPython(item)
                                                },
                                              },
                                            },
                                            [_vm._v(_vm._s(item.title))]
                                          ),
                                        ]
                                      ),
                                      _c("span", {
                                        staticClass: "fr",
                                        style:
                                          "color" +
                                          ":" +
                                          _vm.pythonColor(item.isCorrect),
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.pythonText(item.isCorrect)
                                          ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              }),
                              0
                            ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              slot: "reference",
                              round: "",
                              size: "small",
                              type: "info",
                            },
                            slot: "reference",
                          },
                          [_vm._v("提交记录")]
                        ),
                      ],
                      2
                    ),
                    _c(
                      "el-carousel",
                      {
                        ref: "pythonCarousel",
                        staticStyle: {
                          width: "300px",
                          "border-radius": "50px",
                        },
                        attrs: {
                          height: "45px",
                          autoplay: _vm.autoplay,
                          loop: _vm.autoplay,
                          arrow: "always",
                        },
                        on: { change: _vm.pythonQuestionChange },
                      },
                      _vm._l(
                        _vm.pythonQuestionForTaskList,
                        function (item, index) {
                          return _c(
                            "el-carousel-item",
                            {
                              key: index,
                              model: {
                                value: _vm.activeIndex,
                                callback: function ($$v) {
                                  _vm.activeIndex = $$v
                                },
                                expression: "activeIndex",
                              },
                            },
                            [
                              _c("span", { staticClass: "small" }, [
                                _vm._v("第" + _vm._s(index + 1) + "道题"),
                              ]),
                            ]
                          )
                        }
                      ),
                      1
                    ),
                  ],
                  2
                ),
                _c("iframe", {
                  ref: "leftFrame",
                  staticStyle: { "margin-top": "10px" },
                  attrs: {
                    src: _vm.taskInfo.sourceContent,
                    frameborder: "1",
                    width: "100%",
                    allowfullscreen: "true",
                    height: "99%",
                    seamless: "",
                    sandbox:
                      "allow-scripts allow-modals allow-forms allow-top-navigation allow-same-origin allow-popups",
                  },
                }),
              ]
            : _vm._e(),
          _vm.taskInfo.type === "编程"
            ? [
                _c("preview-question", {
                  key: _vm.sourcePlaceTimer,
                  attrs: { contest_id: _vm.contest_id, taskInfo: _vm.taskInfo },
                  on: { asyncproblemId: _vm.asyncproblemId },
                }),
              ]
            : _vm._e(),
          _vm.taskInfo.type === "测试"
            ? [
                _c("preview-paper", {
                  ref: "previewPaperText",
                  attrs: {
                    taskID: _vm.taskInfo.id,
                    sourceId: _vm.taskInfo.sourceId,
                  },
                }),
              ]
            : _vm._e(),
          _vm.taskInfo.type === "goc"
            ? [
                _c(
                  "el-row",
                  { staticClass: "fr" },
                  [
                    _vm.isShowGoc
                      ? [
                          _vm.editGoC !== null
                            ? [
                                _c(
                                  "span",
                                  { staticStyle: { "margin-right": "15px" } },
                                  [
                                    _vm._v(
                                      " 作品名称：" +
                                        _vm._s(_vm.editGoC.title) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-right": "15px" },
                                    attrs: {
                                      size: "small",
                                      type: "primary",
                                      round: "",
                                      loading: _vm.isGoShow,
                                    },
                                    on: { click: _vm.submitGoc },
                                  },
                                  [_vm._v("保 存 ")]
                                ),
                                _c(
                                  "el-popover",
                                  {
                                    ref: "taskPover",
                                    staticClass: "mr-10",
                                    attrs: {
                                      placement: "top",
                                      width: "350",
                                      trigger: "click",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form",
                                      {
                                        ref: "taskPoverForm",
                                        attrs: {
                                          model: _vm.formData,
                                          rules: _vm.rules,
                                          "label-width": "100px",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "作品名称：",
                                              prop: "titleBesides",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder: "请输入作品名称",
                                              },
                                              on: { input: _vm.forceUpdate },
                                              model: {
                                                value:
                                                  _vm.formData.titleBesides,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "titleBesides",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression:
                                                  "formData.titleBesides",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "text-align": "right",
                                          margin: "0",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "primary",
                                              size: "mini",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.taskConfirm(
                                                  "goc",
                                                  "again"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("确 定")]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "info",
                                              size: "mini",
                                            },
                                            on: { click: _vm.taskClose },
                                          },
                                          [_vm._v("取 消")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { "margin-right": "15px" },
                                        attrs: {
                                          slot: "reference",
                                          size: "small",
                                          type: "primary",
                                          round: "",
                                          loading: _vm.isGoShow,
                                        },
                                        slot: "reference",
                                      },
                                      [_vm._v("另存为 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : [
                                _c(
                                  "el-popover",
                                  {
                                    ref: "taskPover",
                                    staticClass: "mr-10",
                                    attrs: {
                                      placement: "top",
                                      width: "350",
                                      trigger: "click",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form",
                                      {
                                        ref: "taskPoverForm",
                                        attrs: {
                                          model: _vm.formData,
                                          rules: _vm.rules,
                                          "label-width": "100px",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "作品名称：",
                                              prop: "title",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder: "请输入作品名称",
                                              },
                                              on: { input: _vm.forceUpdate },
                                              model: {
                                                value: _vm.formData.title,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "title",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression: "formData.title",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "text-align": "right",
                                          margin: "0",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "primary",
                                              size: "mini",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.taskConfirm("goc")
                                              },
                                            },
                                          },
                                          [_vm._v("确 定")]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "info",
                                              size: "mini",
                                            },
                                            on: { click: _vm.taskClose },
                                          },
                                          [_vm._v("取 消")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { "margin-right": "15px" },
                                        attrs: {
                                          slot: "reference",
                                          size: "small",
                                          type: "primary",
                                          round: "",
                                          loading: _vm.isGoShow,
                                        },
                                        slot: "reference",
                                      },
                                      [_vm._v("保 存 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                          _c(
                            "el-popover",
                            {
                              staticStyle: { "margin-right": "25px" },
                              attrs: {
                                placement: "top-end",
                                width: "230",
                                trigger: "click",
                              },
                              on: { show: _vm.getListGoCTaskCode },
                            },
                            [
                              _vm.gocList.length === 0
                                ? [
                                    _c(
                                      "el-row",
                                      {
                                        staticStyle: { "text-align": "center" },
                                      },
                                      [_c("span", [_vm._v("暂无提交记录")])]
                                    ),
                                  ]
                                : _c(
                                    "ul",
                                    { staticClass: "python-list record-list" },
                                    _vm._l(_vm.gocList, function (item) {
                                      return _c("li", { key: item.id }, [
                                        _c("p", { staticClass: "clearfix" }, [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "title-link pointer",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.previewGoC(item)
                                                },
                                              },
                                            },
                                            [_vm._v(_vm._s(item.title))]
                                          ),
                                        ]),
                                      ])
                                    }),
                                    0
                                  ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    slot: "reference",
                                    round: "",
                                    size: "small",
                                    type: "info",
                                  },
                                  slot: "reference",
                                },
                                [_vm._v("提交记录")]
                              ),
                            ],
                            2
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                _c("iframe", {
                  ref: "gocFrame",
                  attrs: {
                    src: _vm.goSrc,
                    frameborder: "1",
                    width: "100%",
                    allowfullscreen: "true",
                    id: "gocFrame",
                    height: "99%",
                    seamless: "",
                    sandbox:
                      "allow-downloads allow-scripts allow-modals allow-forms allow-top-navigation allow-same-origin allow-popups",
                  },
                }),
              ]
            : _vm._e(),
          _vm.taskInfo.type === "scratch"
            ? [
                _c("el-row", { staticClass: "fr" }, [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        "line-height": "60px",
                        height: "60px",
                      },
                    },
                    [
                      _c(
                        "el-carousel",
                        {
                          ref: "scratchCarousel",
                          staticStyle: {
                            width: "300px",
                            "margin-right": "20px",
                            "border-radius": "50px",
                          },
                          attrs: {
                            height: "45px",
                            autoplay: _vm.autoplay,
                            loop: _vm.autoplay,
                            arrow: "always",
                          },
                          on: { change: _vm.scratchQuestionChange },
                        },
                        _vm._l(
                          _vm.scratchQuestionForTaskList,
                          function (item, index) {
                            return _c(
                              "el-carousel-item",
                              {
                                key: index,
                                model: {
                                  value: _vm.activeIndex,
                                  callback: function ($$v) {
                                    _vm.activeIndex = $$v
                                  },
                                  expression: "activeIndex",
                                },
                              },
                              [
                                _c("span", { staticClass: "small" }, [
                                  _vm._v("第" + _vm._s(index + 1) + "道题"),
                                ]),
                              ]
                            )
                          }
                        ),
                        1
                      ),
                      _vm.isChooseScratch
                        ? [
                            _c(
                              "span",
                              { staticStyle: { "margin-right": "15px" } },
                              [
                                _vm._v(
                                  " 作品名称：" +
                                    _vm._s(_vm.scratchChoose) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-right": "15px" },
                                attrs: {
                                  size: "small",
                                  type: "primary",
                                  round: "",
                                  loading: _vm.isGoShow,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.saveScratch("1")
                                  },
                                },
                              },
                              [_vm._v("保 存 ")]
                            ),
                            _c(
                              "el-popover",
                              {
                                ref: "taskPover",
                                staticClass: "mr-10",
                                attrs: {
                                  placement: "top",
                                  width: "350",
                                  trigger: "click",
                                },
                              },
                              [
                                _c(
                                  "el-form",
                                  {
                                    ref: "taskPoverForm",
                                    attrs: {
                                      model: _vm.formData,
                                      rules: _vm.rules,
                                      "label-width": "100px",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "作品名称：",
                                          prop: "titleBesides",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入作品名称",
                                          },
                                          on: { input: _vm.forceUpdate },
                                          model: {
                                            value: _vm.formData.titleBesides,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "titleBesides",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "formData.titleBesides",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "text-align": "right",
                                      margin: "0",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          size: "mini",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.saveScratch("2")
                                          },
                                        },
                                      },
                                      [_vm._v("确 定")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "info", size: "mini" },
                                        on: { click: _vm.taskClose },
                                      },
                                      [_vm._v("取 消")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-right": "15px" },
                                    attrs: {
                                      slot: "reference",
                                      size: "small",
                                      type: "primary",
                                      round: "",
                                      loading: _vm.isGoShow,
                                    },
                                    slot: "reference",
                                  },
                                  [_vm._v("另存为 ")]
                                ),
                              ],
                              1
                            ),
                          ]
                        : [
                            _c(
                              "el-popover",
                              {
                                ref: "taskPover",
                                staticClass: "mr-10",
                                attrs: {
                                  placement: "top",
                                  width: "350",
                                  trigger: "click",
                                },
                              },
                              [
                                _c(
                                  "el-form",
                                  {
                                    ref: "taskPoverForm",
                                    attrs: {
                                      model: _vm.formData,
                                      rules: _vm.rules,
                                      "label-width": "100px",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "作品名称：",
                                          prop: "title",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入作品名称",
                                          },
                                          on: { input: _vm.forceUpdate },
                                          model: {
                                            value: _vm.formData.title,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "title",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "formData.title",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "text-align": "right",
                                      margin: "0",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          size: "mini",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.saveScratch("1")
                                          },
                                        },
                                      },
                                      [_vm._v("确 定")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "info", size: "mini" },
                                        on: { click: _vm.taskClose },
                                      },
                                      [_vm._v("取 消")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-right": "15px" },
                                    attrs: {
                                      slot: "reference",
                                      size: "small",
                                      type: "primary",
                                      round: "",
                                      loading: _vm.isGoShow,
                                    },
                                    slot: "reference",
                                  },
                                  [_vm._v("保 存 ")]
                                ),
                              ],
                              1
                            ),
                          ],
                      _c(
                        "el-popover",
                        {
                          ref: "taskPoverScratch",
                          attrs: {
                            placement: "top-end",
                            width: "230",
                            trigger: "click",
                          },
                          on: { show: _vm.submitRecord },
                        },
                        [
                          _vm.scratchList.length === 0
                            ? [
                                _c(
                                  "el-row",
                                  { staticStyle: { "text-align": "center" } },
                                  [_c("span", [_vm._v("暂无提交记录")])]
                                ),
                              ]
                            : _c(
                                "ul",
                                { staticClass: "python-list record-list" },
                                _vm._l(_vm.scratchList, function (item) {
                                  return _c("li", { key: item.id }, [
                                    _c(
                                      "p",
                                      { staticClass: "clearfix" },
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "fl",
                                            attrs: {
                                              effect: "dark",
                                              content:
                                                item.createTime.substring(
                                                  0,
                                                  19
                                                ),
                                              placement: "top-start",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "title-link pointer",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.previewScratch(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(_vm._s(item.title))]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ])
                                }),
                                0
                              ),
                          _c(
                            "el-button",
                            {
                              staticStyle: { "margin-right": "15px" },
                              attrs: {
                                slot: "reference",
                                type: "info",
                                round: "",
                                plain: "",
                                size: "small",
                              },
                              slot: "reference",
                            },
                            [_vm._v("提交记录 ")]
                          ),
                        ],
                        2
                      ),
                    ],
                    2
                  ),
                ]),
                _c("iframe", {
                  key: _vm.taskID,
                  ref: "scratchFrame",
                  attrs: {
                    src: _vm.srcPath,
                    frameborder: "1",
                    allowfullscreen: "true",
                    width: "100%",
                    height: "92%",
                    seamless: "",
                    sandbox:
                      "allow-scripts allow-modals allow-forms allow-top-navigation allow-same-origin allow-popups",
                  },
                }),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }