<template>
  <div id="container" style="width: 100%"  :style="{ height: autoHeight - 100 + 'px' }">

  </div>
</template>
<script>
import { getTaskInfo,previewFile }  from '@/apiteacher/teacher'
export default {
  name: 'PdfPreview',
  props: {
    taskID: {
      default:'',
      type:String
    }
  },
  data(){
    return {
      pdfUrl:"",
      autoHeight:0,
    }
  },
  watch: {
    taskID() {
      this.getTaskInfo()
    }
  },
  mounted: function () {
    this.autoHeight = document.documentElement.clientHeight;
    this.getTaskInfo()
  },
  methods: {
    async getTaskInfo () {
      const res = await getTaskInfo({
        id: this.taskID
      })
      this.previewFile(res.body.sourceId)
    },
    async previewFile(sourceId) {
      const res = await previewFile({
        sourceId: sourceId
      })

      this.$nextTick(()=> {
        var instance = aliyun.config({
          mount: document.querySelector('#container'),
          url: res.body.previewURL,

        })
        instance.setToken({
          token: res.body.accessToken,
        })
      })
    }
  }
}
</script>
<style>
.pdf {
  top: 0;
  width: 80%;
  margin-left: 10%;
  z-index: 1000;
}
</style>
