<template>
  <el-container class="container">
    <el-main :class="getStateTaskInfo(taskInfo.type)">
      <!-- taskInfo：{{taskInfo}} -->
      <!-- showCode:{{showCode}} -->
      <template v-if="taskInfo.type === '图文'">
        <el-row class="content">
          <el-col :span="20">
            <div v-html="taskInfo.sourceContent" style="line-height: 25px;"></div>
          </el-col>
          <!-- <div v-html="taskInfo.sourceContent" style="line-height: 25px;"></div> -->
        </el-row>
      </template>
      <template v-if="taskInfo.type === '上传' ">
        <el-row class="content">
          <el-col :span="20">
            <div v-html="taskInfo.sourceContent" style="line-height: 25px;"></div>
          </el-col>
        </el-row>
      </template>

      <template v-if="taskInfo.type === '外链'">
        <iframe
          :src="taskInfo.sourceContent"
          frameborder="1"
          allowfullscreen="true"
          width="100%"
          height="99%"
          seamless
          sandbox="allow-downloads allow-scripts allow-modals allow-forms allow-top-navigation allow-same-origin allow-popups"
        ></iframe>
      </template>
      <template v-if="taskInfo.type === 'python'">
        <div style="display: flex;align-items: center;line-height: 60px;height: 60px;">
          <template v-if="editPython!== null">
          <span style="margin-right: 15px;">
            作品名称：{{ editPython.title }}
          </span>
            <el-button size="small" type="primary" round @click="savePython" style="margin-right: 15px;"
                       :loading="isGoShow">保 存
            </el-button>
            <el-popover
              ref="taskPover"
              placement="top"
              width="350"
              trigger="click"
              class="mr-10"
            >
              <el-form ref="taskPoverForm" :model="formData" :rules="rules" label-width="100px">
                <el-form-item label="作品名称：" prop="titleBesides">
                  <el-input
                    placeholder="请输入作品名称"
                    @input="forceUpdate"
                    v-model.trim="formData.titleBesides">
                  </el-input>
                </el-form-item>
              </el-form>
              <div style="text-align: right; margin: 0">
                <el-button type="primary" size="mini" @click="taskConfirm('python', 'again')">确 定</el-button>
                <el-button type="info" size="mini" @click="taskClose">取 消</el-button>
              </div>
              <el-button slot="reference" size="small" type="primary" round style="margin-right: 15px;"
                         :loading="isGoShow">另存为
              </el-button>
            </el-popover>
          </template>

          <template v-else>
            <el-popover
              ref="taskPover"
              placement="top"
              width="350"
              trigger="click"
              class="mr-10"
            >
              <el-form ref="taskPoverForm" :model="formData" :rules="rules" label-width="100px">
                <el-form-item label="作品名称：" prop="title">
                  <el-input
                    placeholder="请输入作品名称"
                    @input="forceUpdate"
                    v-model.trim="formData.title">
                  </el-input>
                </el-form-item>
              </el-form>
              <div style="text-align: right; margin: 0">
                <el-button type="primary" size="mini" @click="taskConfirm('python')">确 定</el-button>
                <el-button type="info" size="mini" @click="taskClose">取 消</el-button>
              </div>
              <el-button slot="reference" size="small" type="primary" round style="margin-right: 15px;"
                         :loading="isGoShow">保 存
              </el-button>
            </el-popover>
          </template>
          <el-popover
            placement="top-end"
            width="230"
            trigger="click"
            ref="popoverPython"
            style="margin-right: 25px;"
            @show="getListPythonTaskCode"
          >
            <template v-if="pythonList.length===0">
              <el-row style="text-align: center;">
                <span>暂无提交记录</span>
              </el-row>
            </template>
            <ul class="python-list record-list" v-else>
              <li v-for="(item) of pythonList" :key="item.id">
                <p class="clearfix">
                  <el-tooltip class="fl " effect="dark" :content="item.createTime" placement="top-start">
                    <span class="title-link pointer" @click="previewPython(item)">{{ item.title }}</span>
                  </el-tooltip>
                  <span v-text="pythonText(item.isCorrect)" :style="'color' + ':' + pythonColor(item.isCorrect)"
                        class="fr"></span>
                </p>
              </li>
            </ul>
            <el-button slot="reference" round size="small" type="info">提交记录</el-button>
          </el-popover>
          <el-carousel ref="pythonCarousel" height="45px" style="width: 300px;border-radius: 50px" :autoplay="autoplay" :loop="autoplay"
                       @change="pythonQuestionChange" arrow="always">
            <el-carousel-item v-for="(item,index) in pythonQuestionForTaskList" v-model="activeIndex" :key="index">
              <span class="small">第{{ index + 1 }}道题</span>
            </el-carousel-item>
          </el-carousel>
        </div>
        <iframe
          style="margin-top: 10px"
          :src="taskInfo.sourceContent"
          frameborder="1"
          width="100%"
          allowfullscreen="true"
          ref="leftFrame"
          height="99%"
          seamless
          sandbox="allow-scripts allow-modals allow-forms allow-top-navigation allow-same-origin allow-popups"
        ></iframe>
      </template>
      <template v-if="taskInfo.type === '编程'">
        <preview-question
          :contest_id="contest_id"
          :taskInfo="taskInfo"
          @asyncproblemId="asyncproblemId"
          :key="sourcePlaceTimer"
        ></preview-question>
      </template>
      <template v-if="taskInfo.type === '测试'">
        <preview-paper ref="previewPaperText" :taskID="taskInfo.id" :sourceId='taskInfo.sourceId'></preview-paper>
      </template>
      <template v-if="taskInfo.type === 'goc'">
        <!-- <el-row style="margin-top: 20px"> -->
        <el-row class="fr">
          <template v-if="isShowGoc">
            <template v-if="editGoC!== null">
              <span style="margin-right: 15px;">
                作品名称：{{ editGoC.title }}
              </span>
              <el-button size="small" type="primary" round @click="submitGoc" style="margin-right: 15px;"
                         :loading="isGoShow">保 存
              </el-button>
              <el-popover
                ref="taskPover"
                placement="top"
                width="350"
                trigger="click"
                class="mr-10"
              >
                <el-form ref="taskPoverForm" :model="formData" :rules="rules" label-width="100px">
                  <el-form-item label="作品名称：" prop="titleBesides">
                    <el-input
                      placeholder="请输入作品名称"
                      @input="forceUpdate"
                      v-model.trim="formData.titleBesides">
                    </el-input>
                  </el-form-item>
                </el-form>
                <div style="text-align: right; margin: 0">
                  <el-button type="primary" size="mini" @click="taskConfirm('goc', 'again')">确 定</el-button>
                  <el-button type="info" size="mini" @click="taskClose">取 消</el-button>
                </div>
                <el-button slot="reference" size="small" type="primary" round style="margin-right: 15px;"
                           :loading="isGoShow">另存为
                </el-button>
              </el-popover>
            </template>

            <template v-else>
              <el-popover
                ref="taskPover"
                placement="top"
                width="350"
                trigger="click"
                class="mr-10"
              >
                <el-form ref="taskPoverForm" :model="formData" :rules="rules" label-width="100px">
                  <el-form-item label="作品名称：" prop="title">
                    <el-input
                      placeholder="请输入作品名称"
                      @input="forceUpdate"
                      v-model.trim="formData.title">
                    </el-input>
                  </el-form-item>
                </el-form>
                <div style="text-align: right; margin: 0">
                  <el-button type="primary" size="mini" @click="taskConfirm('goc')">确 定</el-button>
                  <el-button type="info" size="mini" @click="taskClose">取 消</el-button>
                </div>
                <el-button slot="reference" size="small" type="primary" round style="margin-right: 15px;"
                           :loading="isGoShow">保 存
                </el-button>
              </el-popover>
            </template>

            <el-popover
              placement="top-end"
              width="230"
              trigger="click"
              @show="getListGoCTaskCode"
              style="margin-right: 25px;"
            >
              <template v-if="gocList.length===0">
                <el-row style="text-align: center;">
                  <span>暂无提交记录</span>
                </el-row>
              </template>
              <ul class="python-list record-list" v-else>
                <li v-for="(item) of gocList" :key="item.id">
                  <p class="clearfix">
                    <span class="title-link pointer" @click="previewGoC(item)">{{ item.title }}</span>
                  </p>
                </li>
              </ul>
              <el-button slot="reference" round size="small" type="info">提交记录</el-button>
            </el-popover>
          </template>
        </el-row>
        <iframe
          :src="goSrc"
          frameborder="1"
          width="100%"
          allowfullscreen="true"
          ref="gocFrame"
          id="gocFrame"
          height="99%"
          seamless
          sandbox="allow-downloads allow-scripts allow-modals allow-forms allow-top-navigation allow-same-origin allow-popups"
        ></iframe>
        <!-- </el-row> -->
      </template>
      <template v-if="taskInfo.type === 'scratch'">
        <el-row class="fr">
          <div style="display: flex;align-items: center;line-height: 60px;height: 60px;">
            <el-carousel ref="scratchCarousel" height="45px" style="width:300px;margin-right: 20px;border-radius: 50px;" :autoplay="autoplay" :loop="autoplay"
                         @change="scratchQuestionChange" arrow="always">
              <el-carousel-item v-for="(item,index) in scratchQuestionForTaskList" v-model="activeIndex" :key="index">
                <span class="small">第{{ index + 1 }}道题</span>
              </el-carousel-item>
            </el-carousel>
            <template v-if="isChooseScratch">
              <span style="margin-right: 15px;">
                作品名称：{{ scratchChoose }}
              </span>
              <el-button size="small" type="primary" round @click="saveScratch('1')" style="margin-right: 15px;"
                         :loading="isGoShow">保 存
              </el-button>
              <el-popover
                ref="taskPover"
                placement="top"
                width="350"
                trigger="click"
                class="mr-10"
              >
                <el-form ref="taskPoverForm" :model="formData" :rules="rules" label-width="100px">
                  <el-form-item label="作品名称：" prop="titleBesides">
                    <el-input
                      placeholder="请输入作品名称"
                      @input="forceUpdate"
                      v-model.trim="formData.titleBesides">
                    </el-input>
                  </el-form-item>
                </el-form>
                <div style="text-align: right; margin: 0">
                  <el-button type="primary" size="mini" @click="saveScratch('2')">确 定</el-button>
                  <el-button type="info" size="mini" @click="taskClose">取 消</el-button>
                </div>
                <el-button slot="reference" size="small" type="primary" round style="margin-right: 15px;"
                           :loading="isGoShow">另存为
                </el-button>
              </el-popover>
            </template>

            <template v-else>
              <el-popover
                ref="taskPover"
                placement="top"
                width="350"
                trigger="click"
                class="mr-10"
              >
                <el-form ref="taskPoverForm" :model="formData" :rules="rules" label-width="100px">
                  <el-form-item label="作品名称：" prop="title">
                    <el-input
                      placeholder="请输入作品名称"
                      @input="forceUpdate"
                      v-model.trim="formData.title">
                    </el-input>
                  </el-form-item>
                </el-form>
                <div style="text-align: right; margin: 0">
                  <el-button type="primary" size="mini" @click="saveScratch('1')">确 定</el-button>
                  <el-button type="info" size="mini" @click="taskClose">取 消</el-button>
                </div>
                <el-button slot="reference" size="small" type="primary" round style="margin-right: 15px;"
                           :loading="isGoShow">保 存
                </el-button>
              </el-popover>
            </template>
            <el-popover
              ref="taskPoverScratch"
              placement="top-end"
              width="230"
              trigger="click"
              @show="submitRecord"
            >
              <template v-if="scratchList.length===0">
                <el-row style="text-align: center;">
                  <span>暂无提交记录</span>
                </el-row>
              </template>
              <ul class="python-list record-list" v-else>
                <li v-for="(item) of scratchList" :key="item.id">
                  <p class="clearfix">
                    <!-- <span class="fl title-link pointer" @click="previewScratch(item)">{{item.title}}</span> -->
                    <el-tooltip class="fl " effect="dark" :content="item.createTime.substring(0, 19)"
                                placement="top-start">
                      <span class="title-link pointer" @click="previewScratch(item)">{{ item.title }}</span>
                    </el-tooltip>
                  </p>
                </li>
              </ul>
              <el-button slot="reference" type="info" style="margin-right: 15px;" round plain size="small">提交记录
              </el-button>
            </el-popover>
          </div>
        </el-row>
        <iframe
          ref="scratchFrame"
          :src="srcPath"
          frameborder="1"
          :key="taskID"
          allowfullscreen="true"
          width="100%"
          height="92%"
          seamless
          sandbox="allow-scripts allow-modals allow-forms allow-top-navigation allow-same-origin allow-popups">
        </iframe>
      </template>
    </el-main>
  </el-container>
</template>

<script>
import previewQuestion from '@/componentsTeacher/curriculum-manage/previewQuestion'
import previewPaper from '@/componentsTeacher/curriculum-manage/previewPaper'
import { showLoading, hideLoading } from '@/utils/loading'
import { getToken } from '@/utils/auth'
import {
  getListQuestionforTask,
  taskScratchList,
  listPythonTaskCode,
  gocList,
  submitPythonTask,
  submitGoc,
  getTaskInfo,
  getUploadTaskInfo
} from '@/apiteacher/teacher'
import Prism from 'prismjs' //引入插件

export default {
  props: {
    taskID: {
      default: '',
      type: String,
    },
  },
  watch: {
    taskID: {
      handler (val) {
        this.taskID = val
        this.$nextTick(() => {
          if (this.$refs.previewPaperText !== undefined) {
            this.$refs.previewPaperText.clearTime()
          }
          this.isChooseScratch = false
          this.scratchList = []
        })
        this.getTaskInfo()
      },
      deep: true,
      immediate: true,
    },
  },
  components: {
    previewPaper,
    previewQuestion
  },
  data () {
    return {
      currentBatch: '',
      pythonQuestionForTaskList: [],
      scratchQuestionForTaskList: [],
      autoplay: false,
      sourcePlaceTimer: 0,
      gocList: [], // goc提交记录
      goSrc: '', // goc任务外链
      srcPath: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_ATTRS+'?timeThemb='+new Date() : process.env.VUE_APP_PREVIEW_ADDRESS + process.env.VUE_APP_PREVIEW_ATTR+'?timeThemb='+new Date(),// scratch服务器地址
      gocPath: process.env.VUE_APP_GOC_ADDRESS,
      isShowGoc: false,
      gocSourceContent: null,
      isGoShow: false,
      editGoC: null,
      submitAgainGocState: false,
      isBesides: false,
      editPython: null,
      pythonList: [],
      formData: {
        titleBesides: '',
      },
      isChooseScratch: false,
      scratchChoose: '',
      scratchList: [],
      scratchCreateTime: '',
      taskInfo: {},
      contest_id: '', // 题库ID
      paper_id: '', // 试卷ID
      pythonFlag: false,
      minFileSize: 0,
      maxFileSize: 0,
      uploadType: 0,
      showCode: '', // 查看学生端提交的代码
      activeIndex: '',
      rules: {
        title: [{ required: true, message: '请填写作品名称', trigger: 'blur' }],
        titleBesides: [{ required: true, message: '请填写作品名称', trigger: 'blur' }]
      },
    }
  },
  mounted () {
    window.addEventListener('message', this.receiveMessage, true)
  },
  beforeDestroy () {
    window.removeEventListener('message', this.receiveMessage, true)
  },
  methods: {
    asyncproblemId(data) {
      this.$emit('asyncproblemId',{problemId:data.problemId})
    },
    scratchQuestionChange (value) {
      this.$refs.taskPoverScratch.doClose()
      this.isChooseScratch = false
      this.scratchCreateTime = this.scratchQuestionForTaskList[value].createTime.substring(0, 19)
      this.currentBatch = this.scratchQuestionForTaskList[value].batch
      let data = {
        index:value
      }
      this.$emit('synchroIndex',data)
      this.$refs.scratchFrame.contentWindow.postMessage({
        pageType: 'previewSwitchrecord',
        filePath: this.scratchQuestionForTaskList[value].sourceScratchTemplate.file,
        taskId: this.taskInfo.id,
        batch: this.scratchQuestionForTaskList[value].batch,
        token: localStorage.getItem('token_business'),
        fileName: this.scratchQuestionForTaskList[value].sourceScratchTemplate.title
      }, '*')
    },
    pythonQuestionChange (value) {
      this.$refs.popoverPython.doClose()
      this.editPython = null
      let code = this.pythonQuestionForTaskList[value].content
      this.currentBatch = this.pythonQuestionForTaskList[value].batch
      this.$refs.leftFrame.contentWindow.location = this.taskInfo.sourceContent

      setTimeout(() => {
        let data = {
          index:value
        }
        this.$emit('synchroIndex',data)
        this.$refs.leftFrame.contentWindow.postMessage({
          code: code
        }, '*')
      }, 1000)
    },
    async getListQuestionforTask (type) {
      const res = await getListQuestionforTask({
        taskId: this.taskID
      })
      switch (type) {
        case 'python':
          this.pythonQuestionForTaskList = res.body
          this.$nextTick(()=> {
            this.$refs.pythonCarousel.setActiveItem(0)
          })
          if (this.pythonQuestionForTaskList.length !== 0) {
            let code = this.pythonQuestionForTaskList[0].content
            this.currentBatch = this.pythonQuestionForTaskList[0].batch
            this.taskInfo.sourceContent = process.env.VUE_APP_PYTHON_IDE + '?pagetype=teacher&pagesource=preview'
            setTimeout(() => {
              this.$refs.leftFrame.contentWindow.postMessage({
                code: code
              }, '*')
            }, 1000)
          }
          break
        case 'scratch':
          this.scratchQuestionForTaskList = res.body
          this.$refs.scratchCarousel.setActiveItem(0)
          this.currentBatch = this.scratchQuestionForTaskList[0].batch
          setTimeout(() => {
            this.$nextTick(()=> {
              this.$refs.scratchFrame.contentWindow.postMessage({
                pageType: 'studentrecord',
                filePath: this.scratchQuestionForTaskList[0].sourceScratchTemplate.file,
                taskId: this.taskInfo.id,
                batch: this.currentBatch,
                token: localStorage.getItem('token_business'),
                fileName: this.scratchQuestionForTaskList[0].sourceScratchTemplate.title
              }, '*')
            })
          }, 1000)
          break
      }

    },
    receiveMessage (msg) {
      if (msg.data.setState === 'true') {
        if (msg.data.typeCode === 'python') {
          this.submitPythonCode(msg.data.setCode)
        } else {
          this.submitCode(msg.data.setCode)
        }
      }

      if (msg.data === 'pageLoadOver') {
        hideLoading()
      }
      if (msg.data.pageSource === 'successState') {
        hideLoading()
        window.$msg('文件保存成功')
      } else if (msg.data.pageSource === 'failState') {
        window.$msg('文件保存失败')
      }
    },
    pythonText (state) {
      switch (state) {
        case true :
          return '正确'
        case false :
          return '错误'
        case null :
          return '未批阅'
        default :
          return '系统未知状态'
      }
    },
    pythonColor (state) {
      switch (state) {
        case true :
          return '#4DA54D'
        case false :
          return '#f56c6c'
        case null :
          return '#909399'
        default :
          return '#666'
      }
    },
    submitGoc () { // 提交goc代码
      this.updateCode = true
      this.isGoShow = true
      setTimeout(() => {
        this.isGoShow = false
      }, 3000)
      setTimeout(() => {
        this.$refs.gocFrame.contentWindow.postMessage({
          getCode: 'true',
          type: 'tms'
        }, '*')
      }, 0)
    },
    // 预览提交的GoC代码
    previewGoC (item) {
      this.editGoC = item
      this.formData.title = item.title
      console.log('previewGoC', item)
      this.goSrc = process.env.VUE_APP_GOC_ADDRESS
      setTimeout(() => {
        this.$refs.gocFrame.contentWindow.postMessage({
          code: item.code,
          type: 'tms'
        }, '*')
      }, 1000)
    },
    savePython () {
      this.updateCode = true
      setTimeout(() => {
        this.$refs.leftFrame.contentWindow.postMessage({
          getCode: 'true'
        }, '*')
      }, 0)
    },
    previewPython (item) {
      this.editPython = item
      this.formData.title = item.title

      let code = item.code
      code.replace(/↵/g, '<br>')
      code = code.replace(/\r\n/g, '<br>')
      code = code.replace(/\n/g, '<br>')
      code = code.replace(/(\r\n)|(\n)/g, '<br>')
      //替换所有的空格（中文空格、英文空格都会被替换）
      // code = code.replace(/ /g,"&nbsp;")

      this.taskInfo.sourceContent = process.env.VUE_APP_PYTHON_IDE + '?taskId=' + this.taskInfo.id + '&token=' + getToken() + '&pagesource=preview'

      this.$refs.leftFrame.contentWindow.location = this.taskInfo.sourceContent

      setTimeout(() => {
        this.$refs.leftFrame.contentWindow.postMessage({
          code: code
        }, '*')
      }, 1000)
    },
    async submitPythonCode (code) { // 提交python代码
      if (!code) {
        window.$msg('代码不能为空', 2)
      } else {
        var title = ''
        if (this.isBesides) {
          title = this.formData.titleBesides
        } else {
          title = this.formData.title
        }
        const res = await submitPythonTask({
          token: getToken(),
          taskId: this.taskID,
          code: code,
          batch: this.currentBatch,
          title: Boolean(this.updateCode) ? this.editPython.title : title,
          createTime: Boolean(this.editPython) && Boolean(!this.submitAgainGocState) && Boolean(!this.isBesides) ? this.editPython.createTime : null
        })
        if (res.state === 'success') {
          this.$refs['taskPover'].doClose()
          window.$msg('代码提交成功')
          // this.taskGetInfo()
          this.editPython = res.body
          if (this.isBesides) {
            this.formData.titleBesides = ''
          } else {
            this.formData.title = ''
          }
          this.isBesides = false
        } else {
          $msg(res.errMsg, 2)
        }
      }
    },

    async submitCode (code) { // 提交goc代码
      if (!code) {
        window.$msg('代码不能为空', 2)
      } else {
        var title = ''
        if (this.isBesides) {
          title = this.formData.titleBesides
        } else {
          title = this.formData.title
        }
        const res = await submitGoc({
          taskId: this.taskID,
          code: code,
          title: Boolean(this.updateCode) ? this.editGoC.title : title,
          createTime: Boolean(this.editGoC) && Boolean(!this.submitAgainGocState) && Boolean(!this.isBesides) ? this.editGoC.createTime : null
        })
        if (res.state === 'success') {
          this.$refs['taskPover'].doClose()
          window.$msg('代码提交成功')
          this.editGoC = res.body
          if (this.isBesides) {
            this.formData.titleBesides = ''
          } else {
            this.formData.title = ''
          }
          this.isBesides = false
          // this.taskGetInfo()
        } else {
          $msg(res.errMsg, 2)
        }
      }
    },

    async getListGoCTaskCode () {
      const res = await gocList({
        taskId: this.taskID,
        pageNum: 1,
        pageSize: 10000,
      })
      if (res.state === 'success') {
        this.gocList = res.body.list
      } else {
        $msg(res.errMsg, 2)
      }
    },
    async getListPythonTaskCode () {
      const res = await listPythonTaskCode({
        taskId: this.taskID,
        batch: this.currentBatch,
        pageNum: 1,
        pageSize: 10000,
      })
      if (res.state === 'success') {
        this.pythonList = res.body.list
      } else {
        $msg(res.errMsg, 2)
      }
    },
    forceUpdate () {
      this.$forceUpdate()
    },
    // 请求学生scratch提交记录
    async submitRecord () {
      const res = await taskScratchList({
        taskId: this.taskID,
        batch: this.currentBatch,
        pageNum: 1,
        pageSize: 10000,
      })
      if (res.state === 'success') {
        this.scratchList = res.body.list
      } else {
        $msg(res.errMsg, 2)
      }
    },
    previewScratch (row) {
      this.isChooseScratch = true
      this.scratchCreateTime = row.createTime.substring(0, 19)
      this.scratchChoose = row.title
      this.$refs.scratchFrame.contentWindow.postMessage({
        pageType: 'previewSwitchrecord',
        filePath: row.file,
        batch: this.currentBatch,
        taskId: row.taskId,
        token: localStorage.getItem('token_business'),
        fileName: row.title
      }, '*')
    },

    saveScratch (flag) {
      let thembFileName = ''
      if (flag === '2') {
        if (!this.formData.titleBesides) {
          window.$msg('请填写作品名称', 2)
          return false
        } else {
          thembFileName = this.formData.titleBesides.indexOf('.sb3') !== -1 ? this.formData.titleBesides : this.formData.titleBesides + '.sb3'
        }
      } else if (flag === '1') {
        if (this.isChooseScratch) {
          thembFileName = this.scratchChoose
        } else if (!this.isChooseScratch && !this.formData.title) {
          window.$msg('请填写作品名称', 2)
          return false
        } else if (!this.isChooseScratch && this.formData.title) {
          thembFileName = this.formData.title.indexOf('.sb3') !== -1 ? this.formData.title : this.formData.title + '.sb3'
        }
      }

      let thembpageType = ''
      if (flag === '2') {
        thembpageType = 'studentsaveAsScratch'
      } else if (flag === '1') {
        thembpageType = 'studentsaveScratch'
        if (!this.isChooseScratch) {
          this.scratchCreateTime = this.getCurrent()
        }
      }

      this.taskClose()
      if (!this.isChooseScratch) {
        this.formData.title = ''
      } else {
        this.formData.titleBesides = ''
      }
      showLoading()
      setTimeout(() => {
        this.$refs.scratchFrame.contentWindow.postMessage({
          pageType: 'studentsave',
          taskId: this.taskID,
          batch: this.currentBatch,
          token: localStorage.getItem('token_business'),
          fileName: thembFileName,
          scratchCreatTime: thembpageType === 'studentsaveScratch' ? this.scratchCreateTime : this.getCurrent()
        }, '*')
      }, 1500)
    },
    // 当前系统时间
    getCurrent () {
      var date = new Date()
      var month = date.getMonth() + 1
      var strDate = date.getDate()
      if (month >= 1 && month <= 9) {
        month = '0' + month
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = '0' + strDate
      }
      var currentDate = date.getFullYear() + '-' + month + '-' + strDate
        + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds()
      return currentDate
    },
    taskConfirm (state, type) {
      this.$refs.taskPoverForm.validate(valid => {
        if (valid) {
          if (type === 'again') {
            console.log('isBesides show')
            this.isBesides = true
            this.updateCode = false
          }
          console.log('state', state)
          switch (state) {
            case 'goc':
              return setTimeout(() => {
                this.$refs.gocFrame.contentWindow.postMessage({
                  getCode: 'true',
                  type: 'tms'
                }, '*')
              }, 0)
            case 'python':
              return setTimeout(() => {
                this.$refs.leftFrame.contentWindow.postMessage({
                  getCode: 'true'
                }, '*')
              }, 0)
          }
        }
      })
    },
    taskClose () {
      this.$refs['taskPover'].doClose()
    },
    async getUploadTaskInfo (id) {
      const response = await getUploadTaskInfo({
        taskId: id
      })
      this.minFileSize = response.body.minSize
      this.maxFileSize = response.body.maxSize
      this.uploadType = response.body.extList
    },
    async getTaskInfo () {
      const res = await getTaskInfo({
        id: this.taskID
      })
      this.taskInfo = { ...res.body }

      if (this.taskInfo.type === 'python') {
        this.getListQuestionforTask('python')
      } else if (this.taskInfo.type === '上传') {
        this.getUploadTaskInfo(this.taskInfo.id)
      } else if (this.taskInfo.type === 'scratch') {
        this.getListQuestionforTask('scratch')
        // window.addEventListener('message', this.receiveScratch,true);
      } else if (this.taskInfo.type === 'goc') {
        let goObj = JSON.parse(res.body.sourceContent)
        let obj = {
          mode: 5,
          win: '010',
          loop: 1,
          time: 3000,
          title: 'GoC',
          path: '',
          cin: ''
        }
        this.isShowGoc = true
        this.goSrc = process.env.VUE_APP_GOC_ADDRESS
        setTimeout(() => {
          this.$refs.gocFrame.contentWindow.postMessage({
            code: Boolean(this.showCode) ? this.showCode : goObj.code,
            type: 'tms'
          }, '*')
        }, 1000)
      }
      if (res.body.sourceId) {
        if (res.body.type === '编程') {
          this.sourcePlaceTimer = new Date().getTime()
          this.contest_id = res.body.sourceId
          this.$forceUpdate()
        } else if (res.body.type === '测试') {
          this.paper_id = res.body.sourceId
        }
      }

      this.$nextTick(() => {
        Prism.highlightAll()
      })
    },
    receiveScratch (msg) {
      if (msg.data === 'pageLoadOver') {
        hideLoading()
      }
      if (msg.data.pageSource === 'successState') {
        hideLoading()
        window.$msg('文件保存成功')
      } else if (msg.data.pageSource === 'failState') {
        window.$msg('文件保存失败')
      }
    },
    getStateTaskInfo (state) {
      switch (state) {
        case '测试' :
          return 'test-main'
        case '编程' :
          return 'bc-main'
        default :
          return 'main'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.python-list {
  margin: 0;
  padding: 5px 8px;

  li {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.record-list {
  max-height: 500px;
  overflow: auto;
}

/deep/ .container {
  overflow: scroll;
  width: 100%;
  background-color: #e5e5e5;
  height: 100%;

  .header {
    display: flex;
    align-items: center;
    box-sizing: border-box;
  }

  .main {
    background: #fff;
    width: 100%;
    height: calc(100vh - 60px);
    overflow: auto;
    padding: 0;

    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 20px;
      padding-bottom: 20px;
      text-align: justify;
      color: #616161;
    }

  }

  .bc-main {
    background: #fff;
    width: 100%;
    min-width: 1200px;
    height: calc(100vh - 60px);
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
  }

  .test-main {
    background: #fff;
    width: 100%;
    height: calc(100vh - 60px);
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
  }

}

/deep/ .el-page-header__left:hover {
  color: #f5c319;
}

.footer-condition {
  display: inline-block;
  margin-right: 20px;
  font-size: 14px;
  color: #c1c1c1;

  &
  :hover {
    color: #f5c319;
    cursor: pointer;
  }

  .el-icon-question {
    display: inline-block;
    margin-right: 5px;
  }

  .condition-con {

    p {
      margin-bottom: 10px;
    }

  }
}

.footer-btn {
  margin-right: 25px;
  color: #212121;
}

p a {
  color: #2ea1f2;
}

/* table 样式 */
table {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
}

table td,
table th {
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 3px 5px;
}

table th {
  border-bottom: 2px solid #ccc;
  text-align: center;
}

/* blockquote 样式 */
blockquote {
  display: block;
  border-left: 8px solid #d0e5f2;
  padding: 5px 10px;
  margin: 10px 0;
  line-height: 1.4;
  font-size: 100%;
  background-color: #f1f1f1;
}

/* code 样式 */
code {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  background-color: #f1f1f1;
  border-radius: 3px;
  padding: 3px 5px;
  margin: 0 3px;
}

pre code {
  display: block;
}

/* ul ol 样式 */
ul, ol {
  margin: 10px 0 10px 20px;
}

.el-carousel__item span {
  width: 100%;
  display: flex;
  justify-content: center;
  color: #000000;
  font-size: 14px;
  padding-bottom: 5px;
  line-height: 45px;
  margin: 0;
}

.el-carousel__item:nth-child(n) {
  background-color: #99a9bf;
}

/deep/ .el-carousel__indicator--horizontal {
  display: none;
}
</style>
