<template>
  <el-dialog
    v-if="dialogVisible"
    :title="title"
    :visible.sync="dialogVisible"
    width="600px"
  >
    <el-form
      :model="formInfo"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="作品名称：" prop="title" style="margin-bottom:16px">
        <el-input style="margin-left:-100px"  v-model="formInfo.title"></el-input>
      </el-form-item>
      <el-form-item label="作品简介：" prop="description">
        <el-input style="margin-left:-100px" type="textarea" v-model="formInfo.description"></el-input>
      </el-form-item>
      <div style="text-align:center">
        <el-button type="primary" @click="saveFile">提交</el-button>
        <el-button  @click="dialogVisible=false">取消</el-button>
      </div>
    </el-form>
  </el-dialog>
</template>
<script>

export default {
  props: {
    formInfos: {
      type: Object,
      default() {
        return {};
      },
    },
    title:{
      type: String,
      default:'列表'
    },
    rules: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  created() {
    this.formInfo = { ...this.formInfos };
  },
  watch: {
    formInfos: {
      handler(val) {
        this.formInfo = { ...this.formInfos };
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      dialogVisible: false,
      formInfo: {},
    };
  },
  methods: {
    saveFile() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$emit("saveFile", this.formInfo);
        }
      });
    },
    open() {
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
    },
  },
};
</script>
<style scoped>
.demo-ruleForm{
  padding: 32px 0 32px 32px;
}

</style>