var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.worksInfo
    ? _c(
        "el-dialog",
        {
          staticClass: "dialog-style",
          attrs: {
            top: "20px",
            title: "列表信息",
            visible: _vm.worksInfo,
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.worksInfo = $event
            },
            close: _vm.close,
            open: _vm.getWorksList,
          },
        },
        [
          _c(
            "el-row",
            { staticClass: "item-box", attrs: { gutter: 40 } },
            _vm._l(_vm.PythonList, function (item, index) {
              return _c(
                "el-col",
                {
                  key: index,
                  staticStyle: { "margin-top": "10px" },
                  attrs: { span: 8 },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "item-style",
                      staticStyle: { "text-align": "center" },
                      on: {
                        click: function ($event) {
                          return _vm.showItem(item)
                        },
                      },
                    },
                    [
                      _c("div", { staticStyle: { padding: "10px" } }, [
                        _c("img", {
                          attrs: {
                            height: "140",
                            width: "180",
                            src: item.cover
                              ? item.cover
                              : "/scratchJr/svglibrary/Jr.png",
                            alt: "",
                          },
                        }),
                      ]),
                      _c("h4", [_vm._v(_vm._s(item.title))]),
                    ]
                  ),
                ]
              )
            }),
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }