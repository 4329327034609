<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    append-to-body
    width="60%"
    top="7vh"
  >
    <span v-text="statusText"></span>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="close" size="mini">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    statusText: {
      default: '',
      type: String
    },
    show: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {}
  },
  methods: {
    close () {
      this.$emit('close')
      this.$emit('checkResult')
    }
  }
}
</script>