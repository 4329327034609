<template>
  <div class="task-con">
    <!-- examBeginTime：{{examBeginTime}} -->
    <el-row :gutter="24">
      <el-col :xs="24" :sm="24" :md="24" :lg="18" :xl="18">
        <div class="panel clearfix">
          <p v-text="testTask.title" class="task-tit"></p>
          <el-button size="mini" type="primary" class="fr">答题中</el-button>
        </div>
        <div
          class="panel"
          v-for="(item, index) in testTask.questionList"
          :key="index"
          :id="'anchor_'+ (index+1)"
        >
          <el-row class="topicTit">
            <span class="top-num">{{index+1}}.</span>
            <span class="top-type">【 {{item.question.type}} 】</span>
            <template v-if="testTask.isShowScore === '是'">
              <span class="top-score">{{item.score}} 分</span>
            </template>
          </el-row>

          <el-row>
            <el-row v-html="item.question.stem" class="task-title"></el-row>
          </el-row>

          <ul class="label-con">
            <li
              class="label-list clearfix"
              v-for="(label, index) in item.question.itemList"
              :key="index"
            >
              <span class="fl mr10" v-text="optionLetterName(index) + '.'"></span>
              <div class="fl" v-html="label.content"></div>
            </li>
          </ul>

          <!-- item.answer：{{item.answer}} -->
          <template v-if="item.question.type === '单选'">
            <el-radio-group
              v-model="item.answer"
              v-for="(label, index) in item.question.itemList"
              :key="index"
              @change="forceUpdate"
            >
              <el-radio :label="label.id">{{optionLetterName(index)}}</el-radio>
            </el-radio-group>
          </template>

          <template v-if="item.question.type === '多选'">
            <el-checkbox-group
              @change="forceUpdate"
              v-model="item.answer"
              v-for="(label, index) in item.question.itemList"
              :key="index"
            >
              <el-checkbox :label="label.id">{{optionLetterName(index)}}</el-checkbox>
            </el-checkbox-group>
          </template>

          <template v-if="item.question.type === '简答'">
            <el-input
              type="textarea"
              :rows="5"
              placeholder="请输入你的答案"
              @input="forceUpdate($event)"
              v-model="item.answer"
            ></el-input>
          </template>

          <template v-if="item.question.type === '填空'">
            <el-input
              style="margin-bottom: 10px;"
              v-for="(ele, index) of item.answer"
              :key="index"
              placeholder="请按填空顺序输入填空答案"
              @input="forceUpdate($event)"
              v-model="ele.value"
            ></el-input>
          </template>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
        <el-row class="task-fixed">
          <div class="task-time" v-if="testTask.limitTime !== 0">
            <p>
              倒计时：
              <span> {{hr}}:{{min}}:{{sec}} </span>
            </p>
          </div>
          <div class="task-dtk">
            <h5 class="card-tit">答题卡</h5>
            <ul :class="[Boolean(isSeen)?'card-list-show':'card-list' + ' clearfix']" @mouseenter="onMouseOver"
                @mouseleave="onMouseOut">
              <li
                v-for="(item, index) of testTask.questionList"
                :key="index"
                @click="skipAnchor(index+1)"
                :class="isRespondence(item)"
              >{{index+1}}
              </li>
              <!-- :class="!item.answer || !item.answer.length ? '' : 'bg-card'" -->
            </ul>
            <el-row style="text-align: center;">
              <el-button
                size="small"
                round
                @click="SubPaper"
                type="primary"
                style="color: #212121;margin: 10px 0;"
              >提交试卷
              </el-button>
            </el-row>
          </div>
        </el-row>
      </el-col>

      <exam-status :show="statusDialog" @close="statusDialog=false,statusText=''" :statusText="statusText"
                   @checkResult="checkResult"></exam-status>
    </el-row>
  </div>
</template>

<script>
  import getOptionName from '@/mixins/getOptionName'
  import ExamStatus from '@/componentsTeacher/examStatus'
  import {setTempAnswer,getTempAnswer,getInfo,start,submit,finish,serverTime} from '@/apiteacher/teacher'
  export default {
    mixins: [getOptionName],
    components: { ExamStatus },
    props: {
      taskID: {
        default: '',
        type: String
      },
      sourceId: {
        default: '',
        type: String
      },
      examBeginTime: {  // 开始做试卷的时间
        default: '',
        type: String
      }
    },
    data () {
      return {
        answerList: [], // 答题卡-（数据）
        beingAnswerList: [], // 暂存答案
        libraryAnswerList: [], // 库里面学生暂存的试卷答案
        testTask: {},
        hr: 0,
        min: 0,
        sec: 0, // 计时试卷参数
        startDate: null, // 试卷开始时间
        endDate: null, // 试卷结束时间
        currentTime: null, // 系统当前时间
        timeoutStatus: true, // 定时器状态
        isSeen: false,
        myInterval: null, // 定时器
        statusDialog: false,
        statusText: '',
        timer: null
      }
    },
    created () {
      if (!this.examBeginTime) {
        this.examStart()
      }
      this.getExamInfo()
    },
    mounted () {
      this.myInterval = setInterval(() => {
        this.testTask.questionList.forEach((item, index) => {
          if (!item.answer) {
          } else {
            if (item.question.type === '单选') {
              this.beingAnswerList.push({
                examId: item.examId,
                questionId: item.questionId,
                answer: JSON.stringify([item.answer])
              })
            } else if (item.question.type === '多选') {
              this.beingAnswerList.push({
                examId: item.examId,
                questionId: item.questionId,
                answer: JSON.stringify(item.answer)
              })
            } else if (item.question.type === '简答') {
              this.beingAnswerList.push({
                examId: item.examId,
                questionId: item.questionId,
                answer: item.answer
              })
            } else {
              this.beingAnswerList.push({
                examId: item.examId,
                questionId: item.questionId,
                answer: JSON.stringify(item.answer)
              })
            }
          }
        })
        const res = setTempAnswer(this.beingAnswerList)
        if(res.state === 'success') {
          this.beingAnswerList = []
        }
      }, 300000)
    },

    methods: {
      clearTime () {
        this.timeoutStatus = false
        clearInterval(this.myInterval)
      },
      // 获取试卷暂存答案
      async getTempAnswer () {
        const res = await getTempAnswer({
          examId: this.sourceId
        })
        if(res.state === 'success') {
          this.libraryAnswerList = res.body
          this.libraryAnswerList.forEach((ele, idx) => {
            this.testTask.questionList.forEach((item, index) => {
              if (ele.questionId === item.questionId) {
                if (item.question.type === '单选') {
                  item.answer = JSON.parse(ele.answer)[0]
                } else if (item.question.type === '多选') {
                  item.answer = JSON.parse(ele.answer)
                } else if (item.question.type === '简答') {
                  item.answer = ele.answer
                } else {
                  item.answer = JSON.parse(ele.answer)
                }
              }
            })
          })
          this.$forceUpdate()
          if (this.testTask.limitTime !== 0) {
            this.changeMinutes()
          }
        }
      },
      setTempAnswer () {
        this.testTask.questionList.forEach((item, index) => {
          console.log('setTempAnswer', item)
          if (!item.answer) {
          } else {
            if (item.question.type === '单选') {
              this.beingAnswerList.push({
                examId: item.examId,
                questionId: item.questionId,
                answer: JSON.stringify([item.answer])
              })
            } else if (item.question.type === '多选') {
              this.beingAnswerList.push({
                examId: item.examId,
                questionId: item.questionId,
                answer: JSON.stringify(item.answer)
              })
            } else if (item.question.type === '简答') {
              this.beingAnswerList.push({
                examId: item.examId,
                questionId: item.questionId,
                answer: item.answer
              })
            } else {
              this.beingAnswerList.push({
                examId: item.examId,
                questionId: item.questionId,
                answer: JSON.stringify(item.answer)
              })
            }
          }
        })

        const res = setTempAnswer(this.beingAnswerList)
      },

      isRespondence (item) {
        if (item.question.type === '填空') {
          let fillNum = 0
          item.answer.forEach((ele, idx) => {
            if (!ele.value) {

            } else {
              fillNum++
            }
          })
          if (fillNum) {
            return 'bg-card'
          } else {
            return ''
          }
        } else {
          if (!item.answer || !item.answer.length) {
            return ''
          } else {
            return 'bg-card'
          }
        }
      },

      // 获取试卷详细信息
      async getExamInfo () {
        const res = await getInfo({
          taskId: this.taskID
        })
        if (res.state === 'success') {
          this.$nextTick(async () => {
            this.testTask = Object.assign({}, res.body)
            this.testTask.questionList.forEach((item, index) => {
              if (item.question.type === '单选') {
                item.answer = ''
              } else if (item.question.type === '多选') {
                item.answer = []
              } else if (item.question.type === '简答') {
                item.answer = ''
              } else {
                // 判断有几个填空符号
                item.answer = []
                let fillingSymbol = item.question.stem.split('____').length - 1
                for (let i = 0; i < fillingSymbol; i++) {
                  item.answer.push({
                    value: ''
                  })
                }
              }
            })
            this.startDate = new Date().getTime() // 试卷开始时间
            this.endDate = this.startDate + 60 * Number(this.testTask.limitTime) * 1000 // 试卷结束时间
            const response = await serverTime()
            this.currentTime = new Date(response.body).getTime() // 系统当前时间
            this.getTempAnswer()
          })
        }
      },

      // 试卷开始计时
      async examStart () {
        const res = start({
          taskId: this.taskID
        })
      },

      // 提交试卷
      SubPaper () {
        this.$confirm('确定提交当前试卷吗？', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.automaticSubmit()
          })
          .catch(() => {
            return false
          })
      },

      forceUpdate () {
        this.$forceUpdate()
      },

      // 自动提交试卷
      async automaticSubmit () {
        clearTimeout(this.timer)
        this.testTask.questionList.forEach((item, index) => {
          if (item.answer.length === 0) {
            // console.log('未答题')
          } else {
            // console.log('已答题')
            if (item.question.type === '单选') {
              this.answerList.push({
                questionId: item.questionId,
                answer: JSON.stringify([item.answer])
              })
            } else if (item.question.type === '多选') {
              this.answerList.push({
                questionId: item.questionId,
                answer: JSON.stringify(item.answer)
              })
            } else if (item.question.type === '简答') {
              this.answerList.push({
                questionId: item.questionId,
                answer: item.answer
              })
            } else {
              let answer = []
              item.answer.forEach((ele, idx) => {
                answer.push(ele.value)
              })

              this.answerList.push({
                questionId: item.questionId,
                answer: JSON.stringify(answer)
              })
            }
          }
        })
        const res = await submit({
          taskId: this.taskID,
          answerList: this.answerList
        })
        if (res.state === 'success') {
          $msg('试卷提交成功')
          clearInterval(this.myInterval)
          // const res = await finish({
          //   taskId: this.taskID
          // })
          // if (res.state === 'success') {
          //   if (!this.statusDialog) {
          //     this.$emit('checkResult')
          //   }
          // } else {
          //   $msg(res.errMsg, 2)
          // }
        } else {
          $msg(res.errMsg, 2)
        }
      },

      checkResult () {
        this.$emit('checkResult')
      },
      skipAnchor (index) {
        let domAnchor = `#anchor_${index}`
        document.querySelector(domAnchor).scrollIntoView(true)
      },

      // 倒计时
      changeMinutes () {
        this.currentTime += 1000
        if (Number(this.endDate) - Number(this.currentTime) < 0) {
          console.log('333333')
          this.timeoutStatus = false
          this.statusText = '试卷时间已耗完，已自动提交试卷'
          this.automaticSubmit()
          this.statusDialog = true
        } else {

          const now = Date.parse(new Date(this.currentTime))
          console.log('now', now)

          const end = Date.parse(new Date(this.endDate))
          console.log('end', end)
          const msec = end - now
          let hr = parseInt(msec / 1000 / 60 / 60 % 24)
          let min = parseInt(msec / 1000 / 60 % 60)
          let sec = parseInt(msec / 1000 % 60)
          this.hr = hr > 9 ? hr : '0' + hr
          this.min = min > 9 ? min : '0' + min
          this.sec = sec > 9 ? sec : '0' + sec
          const that = this
          if (that.hr === '00' && that.min === '00' && that.sec === '00') {
            that.timeoutStatus = false
            that.automaticSubmit()
          }
          if (that.timeoutStatus) {
            this.timer = setTimeout(() => {
              that.changeMinutes()
            }, 1000)
          }
        }

      },

      onMouseOver () {
        this.isSeen = true
      },

      onMouseOut () {
        this.isSeen = false
      },
    }
  }
</script>

<style lang="scss" scoped>
  .task-tit {
    font-size: 24px;
    font-weight: 700;
    color: #444;
  }

  .task-fixed {
   position:absolute !important;
    top:0px !important;
    width: 100%;
    margin-bottom: 35px;
    overflow: auto;

  .task-time {
    padding: 0 5px 10px 5px;
  }

  .task-dtk {
    background: #fff;
    box-sizing: border-box;
    padding: 15px 15px 20px 21px;
    box-sizing: border-box;
    border: 1px solid #dddddd;
    border-radius: 4px;
  }

  }

  .topicTit {
    font-size: 20px;
    color: #212121;
    margin-bottom: 10px;

  span {
    display: inline-block;
  }

  .top-num {
    margin-right: 10px;
  }

  .top-type {
    margin-right: 10px;
  }

  }

  .label-list {
    line-height: 30px;
    margin-bottom: 10px;
    width: 70%;
    box-sizing: border-box;
    padding-left: 20px;
  }

  .task-title {
    display: inline-block;
    color: #474747;
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .card-tit {
    font-size: 18px;
    color: #212121;
  }

  .card-list {
    position: relative;
    max-height: 230px;
    overflow: hidden;
    margin-top: 10px;

  li {
    float: left;
    width: 30px;
    height: 30px;
    box-sizing: border-box;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    text-align: center;
    line-height: 30px;
    margin-right: 10px;
    color: #bdbdbd;
    font-size: 14px;
    margin-bottom: 10px;

  &
  :hover {
    cursor: pointer;
  }

  }
  }

  .card-list-show {
    position: relative;
    max-height: 230px;
    overflow: auto;
    margin-top: 10px;

  li {
    float: left;
    width: 30px;
    height: 30px;
    box-sizing: border-box;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    text-align: center;
    line-height: 30px;
    margin-right: 10px;
    color: #bdbdbd;
    font-size: 14px;
    margin-bottom: 10px;

  &
  :hover {
    cursor: pointer;
  }

  }
  }

  .bg-card {
    border: 1px solid #f5c319 !important;
    background-color: #f5c319 !important;
    color: #fff !important;
  }

  .panel {
    padding: 15px;
    margin-bottom: 24px;
    background-color: #fff;
    border: 1px solid #dddddd;
    border-radius: 4px;
  }

  /deep/ .el-radio-group {
    display: inline-block;
    margin-left: 10px;
    padding: 10px 15px;
    margin-bottom: 13px;

  &
  :hover {
    background: #eee;
  }
  }
  /deep/ .el-checkbox-group {
    padding-left: 20px;
    display: inline-block;
    margin-bottom: 13px;
  }

  @media (min-width: 768px) {
      .task-con {
        width: 600px;
        margin: 0 auto 0;
        padding-top: 24px;
        overflow: hidden;
      }

    .task-fixed {
      width: 100%;
      position: static;
      top: 0;
    }
  }

  @media (min-width: 992px) {
      .task-con {
        width: 740px;
        margin: 0 auto 0;
        padding-top: 24px;
        overflow: hidden;
      }

    .task-fixed {
      width: 100%;
      position: static;
      top: 0;
    }
  }

  @media (min-width: 1200px) {
    .task-con {
      width: 1160px;
      margin: 0 auto 0;
      padding-top: 24px;
      overflow: hidden;
    }

    .task-fixed {
      position: fixed;
      z-index: 999;
      top: 83px;
      width: 270px;
      height: auto;
      min-height: 500px;
      overflow: auto;
    }
  }
</style>
